/* Font */
/* @import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css?family=Google+Sans:wght@600&display=swap"); */

@font-face {
  font-family: "Uniform";
  src: url("assets/fonts/Uniform-Light-webfont.woff2") format("woff2"),
    url("assets/fonts/Uniform-Light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Uniform";
  src: url("assets/fonts/Uniform-Regular-webfont.woff2") format("woff2"),
    url("assets/fonts/Uniform-Regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Uniform";
  src: url("assets/fonts/Uniform-Medium-webfont.woff2") format("woff2"),
    url("assets/fonts/Uniform-Medium-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Uniform";
  src: url("assets/fonts/Uniform-Bold-webfont.woff2") format("woff2"),
    url("assets/fonts/Uniform-Bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Brydesfort";
  src: url("assets/fonts/brydesfort-regular-webfont.woff2") format("woff2"),
    url("assets/fonts/brydesfort-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Brydesfort";
  src: url("assets/fonts/brydesfortbold-regular-webfont.woff2") format("woff2"),
    url("assets/fonts/brydesfortbold-regular-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --sidebar-background: 218 24% 20%;
    --sidebar-foreground: 0 0% 98%;
    --sidebar-primary: 0 0% 98%;
    --sidebar-primary-foreground: 240 5.9% 10%;
    --sidebar-accent: 218 24% 27%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    font-family: "Uniform", sans-serif;
  }
}

@layer components {
  /* Component styles */
  .btn {
    @apply rounded-md enabled:hover:opacity-60 px-2 py-1 flex items-center gap-x-2;
  }
  .btn-primary {
    @apply btn bg-fuchsia text-white disabled:bg-gray-400;
  }
  .btn-secondary {
    @apply btn border-1 border-fuchsia text-fuchsia enabled:hover:underline disabled:border-gray-400 disabled:text-gray-400;
  }
  .btn-tertiary {
    @apply btn text-fuchsia hover:underline;
  }
  .btn-fuchsia {
    @apply btn bg-fuchsia text-white disabled:bg-gray-400;
  }
  .btn-offblack {
    @apply btn bg-offblack text-white disabled:bg-gray-700 enabled:hover:bg-gray-600;
  }
  .btn-offwhite {
    @apply btn bg-offwhite text-black disabled:bg-gray-700 enabled:hover:bg-slate-300;
  }

  .nav-link {
    @apply text-black text-left text-base flex items-center gap-x-2 font-semibold px-2 py-1 rounded-2xl disabled:bg-pastel-fuchsia ring-fuchsia hover:ring-2 hover:bg-pastel-fuchsia aria-[current]:bg-pastel-fuchsia aria-[current]:ring-2;
  }

  .nav-offwhite {
    @apply text-black text-xl text-center rounded-2xl py-2 bg-offwhite disabled:bg-zinc-300 enabled:hover:text-fuchsia p-2;
  }
  .nav-offwhite > svg {
    @apply mx-2;
  }

  .rounded-input {
    @apply placeholder-shown:border-light-grey border-fuchsia focus:border-fuchsia border-2 rounded-lg px-2 outline-none;
  }

  .link-fuchsia {
    @apply text-fuchsia underline hover:opacity-60 cursor-pointer;
  }
  .link-offblack {
    @apply text-offblack hover:text-fuchsia underline hover:opacity-60 cursor-pointer;
  }
}
